export const TissInvoice = {
    INVOICE_STATUS_OPEN: 'Aberta', // Manual
    INVOICE_STATUS_CLOSED: 'Fechada', // Manual
    INVOICE_STATUS_FINISHED: 'Finalizada', // Automatic
}

export const TissBatch = {
    BATCH_STATUS_TO_SENT: 'A enviar', // Automatic
    BATCH_STATUS_SENT: 'Enviado', // Manual
    BATCH_STATUS_RECEIVED: 'Recebido', // Manual
    BATCH_STATUS_INVOICED_PARTIAL: 'Faturado parcial', // Automatic
    BATCH_STATUS_INVOICED: 'Faturado', // Automatic
    BATCH_STATUS_FINISHED: 'Finalizado', // Automatic
}

export const TissGuide = {
    GUIDE_SITUATION_IN_PREPARATION: 'Em elaboração', // Manual
    GUIDE_SITUATION_WAITING_AUTHORIZATION: 'Aguardando autorização', // Manual
    GUIDE_SITUATION_AUTHORIZED: 'Autorizada', // Manual
    GUIDE_SITUATION_DENIED: 'Negada', // Manual
    GUIDE_SITUATION_CANCELED: 'Cancelada', // Manual
    GUIDE_SITUATION_SENT: 'Enviado', // Automatic
    GUIDE_SITUATION_OPEN: 'Em aberto', // Automatic
    GUIDE_SITUATION_PAYED: 'Pago', // Automatic
    GUIDE_SITUATION_PARTIALLY_PAID: 'Pago parcialmente', // Automatic
    GUIDE_SITUATION_GLOSSED: 'Glosado', // Automatic
}

export const TissGuideItem = {
    STATUS_ITEM_OPEN: 'Em aberto', // Manual
    STATUS_ITEM_PAID: 'Pago', // Manual
    STATUS_ITEM_PARTIALLY_PAID: 'Pago parcialmente', // Manual
    STATUS_ITEM_GLOSSED: 'Glosado', // Manual
}

export const tissInvoiceStatusOptions = [
    { label: 'Aberta', value: TissInvoice.INVOICE_STATUS_OPEN },
    { label: 'Fechada', value: TissInvoice.INVOICE_STATUS_CLOSED },
    { label: 'Finalizada', value: TissInvoice.INVOICE_STATUS_FINISHED, hidden: true },
]

export const tissBatchStatusOptions = [
    { label: 'A enviar', value: TissBatch.BATCH_STATUS_TO_SENT, hidden: true },
    { label: 'Enviado', value: TissBatch.BATCH_STATUS_SENT },
    { label: 'Recebido', value: TissBatch.BATCH_STATUS_RECEIVED },
    { label: 'Faturado parcial', value: TissBatch.BATCH_STATUS_INVOICED_PARTIAL, hidden: true  },
    { label: 'Faturado', value: TissBatch.BATCH_STATUS_INVOICED, hidden: true  },
    { label: 'Finalizado', value: TissBatch.BATCH_STATUS_FINISHED, hidden: true  },
]

export const guideStatusOptions = [
    { label: 'Selecionar...', value: null },
    { label: 'Em aberto', value: TissGuideItem.STATUS_ITEM_OPEN },
    { label: 'Pago', value: TissGuideItem.STATUS_ITEM_PAID},
    { label: 'Pago parcial', value: TissGuideItem.STATUS_ITEM_PARTIALLY_PAID },
    { label: 'Glosado', value: TissGuideItem.STATUS_ITEM_GLOSSED },
]

export const guideModalSituationOptions = [     
    { label: 'Em elaboração', value: TissGuide.GUIDE_SITUATION_IN_PREPARATION },
    { label: 'Aguardando autorização', value: TissGuide.GUIDE_SITUATION_WAITING_AUTHORIZATION },
    { label: 'Autorizada', value: TissGuide.GUIDE_SITUATION_AUTHORIZED },
    { label: 'Negada', value: TissGuide.GUIDE_SITUATION_DENIED },
    { label: 'Cancelada', value: TissGuide.GUIDE_SITUATION_CANCELED },
    { label: 'Enviado', value: TissGuide.GUIDE_SITUATION_SENT, hidden: true },
    { label: 'Em aberto', value: TissGuide.GUIDE_SITUATION_OPEN, hidden: true },
    { label: 'Pago', value: TissGuide.GUIDE_SITUATION_PAYED, hidden: true },
    { label: 'Pago parcialmente', value: TissGuide.GUIDE_SITUATION_PARTIALLY_PAID, hidden: true },
    { label: 'Glosado', value: TissGuide.GUIDE_SITUATION_GLOSSED, hidden: true },
]