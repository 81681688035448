

export const GUIDE_TYPE_CONSULTATION = 'App\\Models\\TissGuide\\TissGuideConsultation'
export const GUIDE_TYPE_SPSADT = 'App\\Models\\TissGuide\\TissGuideSpSadt'
export const GUIDE_TYPE_HOSPITALIZATION_REQUEST = 'App\\Models\\TissGuide\\TissGuideHospitalizationRequest'
export const GUIDE_TYPE_HOSPITALIZATION_SUMMARY = 'App\\Models\\TissGuide\\TissGuideHospitalizationSummary'
export const GUIDE_TYPE_OTHER_EXPENSE = 'App\\Models\\TissGuide\\TissGuideOtherExpense'
export const GUIDE_TYPE_OTHER_FEE = 'App\\Models\\TissGuide\\TissGuideFee'


export const getTypes = type => {
  if (!type?.split) return
  type = type.split('\\').at(-1)
  const types = {
    TissGuideConsultation: 'Consulta',
    TissGuideSpSadt: 'SP/SADT',
    TissGuideHospitalizationRequest: 'Solicitação de internação',
    TissGuideFee: 'Honorários',
    TissGuideHospitalizationSummary: 'Resumo de internação',
    TissGuideOtherExpense: 'Despesas'
  }
  return types[type]
}


export const onChangeSituation = (tissGuide, situation) => {
  tissGuide.updatedStatus = true
  switch (situation) {
    case 'Pago':
      tissGuide.items_paid = tissGuide.items_total
      tissGuide.items_glossed = 0
    break;
    case 'Glosado':
      tissGuide.items_paid = 0
      tissGuide.items_glossed = tissGuide.items_total
    break;
    case 'Pago parcialmente':
      tissGuide.items_paid = 0
      tissGuide.items_glossed = 0
    break;
    default:
      tissGuide.items_paid = 0
      tissGuide.items_glossed = 0
  }
}

export const onChangeStatus = (tissGuideItem, status) => {
  switch (status) {
    case 'Pago':
      tissGuideItem.value_paid = tissGuideItem.total_value
      tissGuideItem.glossed_value = 0
    break;
    case 'Glosado':
      tissGuideItem.value_paid = 0
      tissGuideItem.glossed_value = tissGuideItem.total_value
    break;
    case 'Pago parcialmente':
      tissGuideItem.value_paid = 0
      tissGuideItem.glossed_value = 0
    break;
    default:
      tissGuideItem.value_paid = 0
      tissGuideItem.glossed_value = 0
  }
}

export const handleCards = cards => {
    const auxCards = []
    const colors = ['var(--dark-blue)', 'var(--neutral-700)', 'var(--states-success)', '#FF6B00', 'var(--blue-500)', 'var(--states-error)']
    Object.keys(cards).map((title, index) => {
        auxCards.push(
        {
          title: title,
          value: cards[title],
          color: colors[index],
          isMoney: title.includes('Valor'),
        },
      )
    })
    return auxCards
}

export const parseGuideType = type => {
  const types = {
    [GUIDE_TYPE_HOSPITALIZATION_REQUEST]: 'Solicitação de internação',
    [GUIDE_TYPE_HOSPITALIZATION_SUMMARY]: 'Resumo de internação',
    [GUIDE_TYPE_CONSULTATION]: 'Consulta',
    [GUIDE_TYPE_SPSADT]: 'SP/SADT',
    [GUIDE_TYPE_OTHER_EXPENSE]: 'Despesas',
    [GUIDE_TYPE_OTHER_FEE]: 'Honorários',
  }
  return types[type] ?? type
}

export const parseItemType = type => {
  const types = {
    'App\\Models\\Procedure': 'Procedimento',
    'App\\Models\\TissGuide\\Import\\TissImportedExpense': 'Despesa',
    'App\\Models\\Warehouse\\Product': 'Item',
  }
  return types[type] ?? type
}

export const normalizeTableNumber = (number) => {
  if (!number) {
    number = 0;
  }
  return String(number).padStart(2, '0');
}


export const normalizeNumber = (number, zeros = 2) => {
  if (!number) {
    number = 0;
  }
  return String(number).padStart(zeros, '0');
}


